import { gql } from '@apollo/client'

export const MERCHANT = gql`
{
  merchant {
    businessName
    subdomain
    customDomain
    location
    phone
    businessDesc
    tagline
    category
    status
    logo
    transactionCostBearer
    personName 
    personId 
    personPhone 
    personEmail 
    coverPhotos
    primaryColor
    secondaryColor
    enforceConsent
    wabaBusinessId,
    wabaPhoneId,
    wabaToken,
    metaAppId,
    letaToken
    mpesaTill
    mpesaShortCode
    mpesaConsumerKey
    mpesaConsumerSecret
    mpesaPassKey
    paystackPublicKey
    paystackSecretKey
  }
}
`

export const GET_MERCHANT_ROOMS = gql`
query GetMerchantRooms {
  merchant {
    id
    rooms {
      id
      name
      tables {
        id
        storeId
        name
        seats
      }
    }
  }
}
`

export const ANALYTICS = gql`
query GetAnalytics($period: PeriodFilter! $storeId: ID) {
  analytics(period: $period storeId: $storeId) {
    ordersTrend {
      date
      count
      revenue
    }
    deliveryOrders
    pickupOrders
    dineinOrders
    takeawayOrders
    totalOrders
    revenue
    newCustomers
    repeatCustomers
    itemAnalysis{
      name
      count
    }
  }
}
`

export const UPDATE_MERCHANT = gql`
mutation UpdateSettings(
  $businessName: String 
  $businessDomain: String 
  $phone: String 
  $logo: Upload
  $logoUrl: String
  $location: String 
  $businessDesc: String 
  $tagline: String 
  $category: String 
  $status: String 
  $primaryColor: String 
  $secondaryColor: String 
  $transactionCostBearer: String
  $personName: String
  $personId: String
  $personPhone: String
  $personEmail: String
  $enforceConsent: Boolean
  $wabaBusinessId: String
  $wabaPhoneId: String
  $wabaToken: String
  $metaAppId: String
  $letaToken: String
  $mpesaTill: String
  $mpesaShortCode: String
  $mpesaConsumerKey: String
  $mpesaConsumerSecret: String
  $mpesaPassKey: String
  $paystackPublicKey: String
  $paystackSecretKey: String
) {
  updateMerchantDetails(
      businessName: $businessName
      businessDomain: $businessDomain 
      phone: $phone
      logo: $logo
      logoUrl: $logoUrl
      location: $location 
      businessDesc: $businessDesc 
      tagline: $tagline
      category: $category 
      status: $status 
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor 
      transactionCostBearer: $transactionCostBearer
      personName: $personName
      personId: $personId
      personPhone: $personPhone
      personEmail: $personEmail
      enforceConsent: $enforceConsent
      wabaBusinessId: $wabaBusinessId
      wabaPhoneId: $wabaPhoneId
      wabaToken: $wabaToken
      metaAppId: $metaAppId
      letaToken: $letaToken
      mpesaTill: $mpesaTill
      mpesaShortCode: $mpesaShortCode
      mpesaConsumerKey: $mpesaConsumerKey
      mpesaConsumerSecret: $mpesaConsumerSecret
      mpesaPassKey: $mpesaPassKey
      paystackPublicKey: $paystackPublicKey
      paystackSecretKey: $paystackSecretKey
  ) {
    businessName
    subdomain
    location
    phone
    businessDesc
    tagline
    category
    logo
    transactionCostBearer
    personName 
    personId 
    personPhone 
    personEmail 
    coverPhotos
    primaryColor
    secondaryColor
    enforceConsent
    wabaBusinessId
    wabaPhoneId
    wabaToken
    metaAppId
    letaToken
    mpesaTill
    mpesaShortCode
    mpesaConsumerKey
    mpesaConsumerSecret
    mpesaPassKey
    paystackPublicKey
    paystackSecretKey
  }
}
`

export const ADD_COVER = gql`
mutation AddCover($image: Upload!) {
  addCoverPhoto(image: $image)
}
`

export const DELETE_COVER = gql`
mutation DeleteCover($imageLink: String!) {
  removeCoverPhoto(imageLink: $imageLink)
}
`

export const SMS_BALANCE = gql`
{
  smsBalance: merchant {
    smsBalance
  }
}
`
